import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { useEffect, useRef, useState } from 'react';

interface NavItemProps {
  category: string;
  href?: string;
  subcategories?: { name: string; href: string }[];
  isOpen: boolean;
  onToggle: () => void;
}

export default function NavItem({ category, href, subcategories, isOpen, onToggle }: NavItemProps) {
  const hasSubcategories = subcategories && subcategories.length > 0;
  const [height, setHeight] = useState<number | undefined>(undefined);
  const ref = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (isOpen) {
      const height = ref.current?.scrollHeight;
      setHeight(height);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  return (
    <div className='border-b border-neutral-200 bg-white'>
      {hasSubcategories ? (
        <>
          <button
            type='button'
            className={`flex h-16 w-full items-center justify-between text-left font-robotoCondensed text-sm font-medium uppercase ${
              isOpen ? 'text-hb-orange' : 'text-neutral-950'
            }`}
            onClick={onToggle}
          >
            <span className='flex items-center px-4'>
              {category}
              {isOpen ? (
                <ChevronUpIcon className='ml-1 h-5 w-5 transition-transform duration-300' />
              ) : (
                <ChevronDownIcon className='ml-1 h-5 w-5 transition-transform duration-300' />
              )}
            </span>
          </button>
          <ul
            ref={ref}
            className='overflow-hidden bg-neutral-50 transition-[height] duration-300 ease-in-out'
            style={{ height: height !== undefined ? `${height}px` : undefined }}
          >
            {subcategories.map((subcategory) => (
              <li key={subcategory.name} className='border-t border-neutral-200'>
                <a
                  href={subcategory.href}
                  className='flex h-16 w-full items-center px-4 font-robotoCondensed text-sm font-medium uppercase text-neutral-950'
                >
                  {subcategory.name}
                </a>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <a
          href={href}
          className='flex h-14 w-full items-center px-4 text-left font-robotoCondensed text-sm font-medium uppercase text-neutral-950'
        >
          {category}
        </a>
      )}
    </div>
  );
}
