import { useState } from 'react';

import HamburgerButton from '@/components/ui/buttons/HamburgerButton';

import NavItem from './NavItem';
import SearchLoginContainer from './SearchLoginContainer';

const navItems = [
  {
    category: 'Outdoors',
    subcategories: [
      {
        name: 'Hunting',
        href: '/hunting',
      },
      {
        name: 'Shooting',
        href: '/shooting',
      },
      {
        name: 'Fishing',
        href: '/fishing',
      },
      {
        name: 'Gear',
        href: '/gear',
      },
      {
        name: 'Conservation',
        href: '/conservation',
      },
    ],
  },
  {
    category: 'Lifestyle',
    subcategories: [
      {
        name: 'Food & Drink',
        href: '/food-drink',
      },
      {
        name: 'Destinations',
        href: '/destinations',
      },
      {
        name: 'Fitness',
        href: '/fitness',
      },
    ],
  },
  {
    category: 'Entertainment',
    subcategories: [
      {
        name: 'Celebrities',
        href: '/celebrities',
      },
      {
        name: 'Music',
        href: '/music',
      },
      {
        name: 'Spotlighting',
        href: '/spotlighting',
      },
    ],
  },
  {
    category: 'News',
    href: '/news',
  },
  {
    category: 'Insider',
    href: '/insider',
  },
  {
    category: 'H&B TV',
    href: '/video-series',
  },
  {
    category: 'Single Issues',
    href: '/magazine',
  },
];

export default function Nav({ isLoggedIn }: { isLoggedIn: boolean }) {
  const [isOpen, setIsOpen] = useState(false);
  const [openCategory, setOpenCategory] = useState<string | null>(null);

  const handleToggleCategory = (category: string) => {
    setOpenCategory((prevCategory) => (prevCategory === category ? null : category));
  };

  return (
    <>
      <HamburgerButton onToggle={setIsOpen} className='relative z-50' />
      <div
        className={`fixed inset-0 bg-neutral-50 transition-transform duration-500 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
      >
        <div className='bg-white border-b border-neutral-200'>
          <div className='h-[72px] border-b border-neutral-200' />
          <SearchLoginContainer isLoggedIn={isLoggedIn} />
        </div>
        <div>
          {navItems.map((item) => (
            <NavItem
              key={item.category}
              {...item}
              isOpen={openCategory === item.category}
              onToggle={() => handleToggleCategory(item.category)}
            />
          ))}
        </div>
      </div>
    </>
  );
}
