import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { useRef } from 'react';

import Button from '@/components/ui/buttons/Button';

import CartButton from './CartButton';

export default function SearchLoginContainer({ isLoggedIn }: { isLoggedIn: boolean }) {
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchInputRef.current) {
      const searchQuery = searchInputRef.current.value.trim().toLowerCase();
      if (searchQuery) {
        window.location.href = `/search?query=${encodeURIComponent(searchQuery)}`;
      } else {
        searchInputRef.current.classList.add('error');
        setTimeout(() => searchInputRef.current?.classList.remove('error'), 500);
      }
    }
  };

  return (
    <div className='flex flex-col gap-3 bg-neutral-50 p-4'>
      <form onSubmit={handleFormSubmit} className='relative'>
        <input
          type='search'
          placeholder='Search...'
          className='h-10 w-full items-center justify-start border border-neutral-200 p-2 pl-8 font-robotoCondensed text-sm font-medium text-neutral-800'
          ref={searchInputRef}
        />
        <MagnifyingGlassIcon className='absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 stroke-2 text-neutral-800' />
      </form>
      <div className='flex gap-2'>
        <a href={isLoggedIn ? '/my-account' : '/login'} className='flex-1'>
          <Button intent='secondary' className='w-full'>
            {isLoggedIn ? 'My Account' : 'Login'}
          </Button>
        </a>
        <a href='/checkout' className='flex-1'>
          <CartButton />
        </a>
      </div>
      <div className='flex flex-col gap-2'>
        <a href='/subscribe' className='flex-1'>
          <Button intent='primary' className='w-full'>
            Subscribe
          </Button>
        </a>
      </div>
      <style>{`
                .error {
                    border-color: #ff0000;
                }
            `}</style>
    </div>
  );
}
