import Button from '@/components/ui/buttons/Button';

import { getTotalItemsCount } from '@/stores/shoppingCart/shoppingCart';

export default function CartButton() {
  const totalItems = getTotalItemsCount();

  return (
    <Button intent='secondary' className='w-full'>
      Your Cart ({totalItems})
    </Button>
  );
}
