import { useState } from 'react';

import Button, { type ButtonProps } from './Button';

interface HamburgerButtonProps extends ButtonProps {
  onToggle?: (isOpen: boolean) => void;
}

const HamburgerButton: React.FC<HamburgerButtonProps> = ({ onToggle, ...buttonProps }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    onToggle?.(newState);
  };

  return (
    <Button
      {...buttonProps}
      intent='icon'
      size='icon'
      className={`hamburger ${isOpen ? 'open' : ''} ${buttonProps.className || ''} !box-border !h-12 !w-12 !rounded-none !border !border-neutral-200`}
      onClick={handleClick}
      aria-label='Menu'
    >
      <div className='hamburger-icon'>
        <span className='hamburger-line' />
        <span className='hamburger-line' />
        <span className='hamburger-line' />
      </div>
      <style>{`
                .hamburger-icon {
                    width: 0.75rem;
                    height: 0.75rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    position: relative;
                }
                .hamburger-line {
                    width: 100%;
                    height: 1.5px;
                    background-color: currentColor;
                    transition: all 0.3s ease;
                    position: absolute;
                    border-radius: 1.5px;
                }
                .hamburger-line:nth-child(1) {
                    top: 0;
                }
                .hamburger-line:nth-child(2) {
                    top: 50%;
                    transform: translateY(-50%);
                }
                .hamburger-line:nth-child(3) {
                    bottom: 0;
                }
                .hamburger.open .hamburger-line:nth-child(1) {
                    top: 50%;
                    transform: translateY(-50%) rotate(45deg);
                }
                .hamburger.open .hamburger-line:nth-child(2) {
                    opacity: 0;
                }
                .hamburger.open .hamburger-line:nth-child(3) {
                    top: 50%;
                    transform: translateY(-50%) rotate(-45deg);
                }
            `}</style>
    </Button>
  );
};

export default HamburgerButton;
